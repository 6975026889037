import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useProject } from '@hooks';

import { ProjectFormValues } from '../../utils/backend-api/projects';
import ProjectForm, { INITIAL_STATE } from './ProjectForm';
import './Projects.scss';

const EditProject = () => {
  const { id: projectId } = useParams();
  const [formValues, setFormValues] = useState<ProjectFormValues>(INITIAL_STATE);
  const { project, isLoadingProject, isFetching } = useProject(projectId);

  useEffect(() => {
    if (!isLoadingProject && !isFetching) {
      for (const key in project) {
        // replace null values with empty string
        if (project[key] === null) {
          project[key] = '';
        }
      }
      const {
        accessLevel,
        accessLevelText,
        article,
        jsonLdData: { alternateName, keywords, ...restJsonLdData },
        ...restProject
      } = project;

      const projectFormValues = {
        accessLevel: {
          label: accessLevelText,
          value: accessLevel || 0,
        },
        article: {
          label: article.title || '',
          value: article.id || '',
        },
        jsonLdData: {
          alternateName: alternateName?.map((name: string) => ({ label: name, value: name })) || [],
          keywords: keywords?.map((keyword: string) => ({ label: keyword, value: keyword })) || [],
          ...restJsonLdData,
        },
        ...restProject,
      };

      setFormValues(projectFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProject, isFetching]);

  if (isLoadingProject || !project) return null;
  return (
    <Container className="EditProject">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-1 text-center">{project.title}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-2">
              <hr className="divider" />
            </Col>
          </Row>
          <ProjectForm formValues={formValues} />
        </Col>
      </Row>
    </Container>
  );
};

export default EditProject;
