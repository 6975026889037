import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '..';
import { createNewsletterSubscriptionAction } from '../../../../utils/backend-api/newsletter-subscription-actions';
import { autocompleteNewsletters } from '../../../../utils/backend-api/newsletters';
import { AutocompleteField } from '../../../FormFields';

const AddSubscriptionForm = ({ identity }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (values, actions) => {
    try {
      const result = await createNewsletterSubscriptionAction({
        newsletterIds: [values.newsletterId.value],
        identityIds: [identity.id],
        type: 'comp',
      });
      if (result) {
        queryClient.invalidateQueries([IDENTITY_KEY, identity.id]);
        toast.success('Comped Newsletter');
        actions.resetForm();
      } else {
        toast.error('Failed to comp Newsletter');
      }
    } catch (e) {
      toast.error(`There was an error comping the newsletter: ${e}`);
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{}}>
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <AutocompleteField
            placeholder="Select a newsletter to add"
            name="newsletterId"
            isCreatable={false}
            fetchMethod={(value) =>
              autocompleteNewsletters({
                fragment: value,
              })
            }
            valueIsInteger
          />
          <Button type="submit" disabled={!formik.values.newsletterId}>
            Add Newsletter Comp
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddSubscriptionForm;
