import { api_react_v1_update_project_path } from '../../routes';
import { AutocompleteOption, AutocompleteRecord } from '../interfaces';
import request from './request';
import { getRecord, getRecords } from './universal';

const MAX_PROJECTS_PER_PAGE = 25;

interface JsonLdData {
  alternateName: string[];
  description: string;
  email: string;
  keywords: string[];
  name: string;
  spatialCoverage: string;
  temporalCoverage: string;
}

interface JsonLdFormData extends Omit<JsonLdData, 'alternateName' | 'keywords'> {
  alternateName: AutocompleteOption[];
  keywords: AutocompleteOption[];
}

export interface Project {
  accessLevel: number;
  accessLevelText: string;
  article: {
    id: number;
    title: string;
  };
  byline: string;
  csvColumns: string;
  description: string;
  desktopImageUrl: string;
  googleSheetId: string;
  id: number;
  jsonLdData: JsonLdData;
  metaDescription: string;
  metaImageUrl: string;
  metaTitle: string;
  mobileImageUrl: string;
  partnerUnlockCode: string;
  slug: string;
  title: string;
}

export interface ProjectFormValues
  extends Omit<Project, 'accessLevel' | 'accessLevelText' | 'article' | 'id' | 'jsonLdData'> {
  accessLevel: AutocompleteRecord;
  article: AutocompleteOption;
  jsonLdData: JsonLdFormData;
}

// TODO: Add type definitions for this interface? -------------
// export interface ProjectSanitizedValues extends Project {}

export interface PaginatedProjects {
  result: Project[];
  nextPage: number;
}

export const getProject = async (projectId: number | string): Promise<Project> => {
  const resp = await getRecord({
    model: 'Project',
    id: projectId,
    attributes: [
      'accessLevel',
      'byline',
      'csvColumns',
      'description',
      'googleSheetId',
      'id',
      'metaDescription',
      'metaTitle',
      'partnerUnlockCode',
      'slug',
      'title',
    ],
    helpers: ['accessLevelText', 'desktopImageUrl', 'metaImageUrl', 'mobileImageUrl'],
    relations: {
      jsonLdData: {
        attributes: [
          'alternateName',
          'description',
          'email',
          'keywords',
          'name',
          'spatialCoverage',
          'temporalCoverage',
        ],
      },
      article: {
        attributes: ['id', 'title'],
      },
    },
  });
  return resp.result;
};

export const getPaginatedProjects = async (page: number): Promise<PaginatedProjects> =>
  await getRecords({
    model: 'Project',
    attributes: ['id', 'title'],
    helpers: ['accessLevelText', 'desktopImageUrl', 'metaImageUrl', 'mobileImageUrl'],
    order: { access_level: 'desc', title: 'asc' },
    page,
    maxPerPage: MAX_PROJECTS_PER_PAGE,
  });

// TODO: make this function for projects -------------
export async function updateProject(values) {
  const resp = await request('put', api_react_v1_update_project_path(), values, true);
  return resp;
}
