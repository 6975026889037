import * as yup from 'yup';

import { imageTest } from '../../../utils/helpers';

const projectSchema = () =>
  yup.object().shape(
    {
      slug: yup.string().required('Slug is required'),
      title: yup.string().required('Title is required'),
      byline: yup.string(),
      description: yup.string().required('Description is required'),
      desktopImageUrl: yup
        .mixed()
        .required('Desktop image is required')
        .test('fileType', 'Unsupported File Format', imageTest),
      mobileImageUrl: yup
        .mixed()
        .notRequired()
        .when('mobileImageUrl', {
          is: (mobileImageUrl) => mobileImageUrl,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),
      googleSheetId: yup.string().notRequired(),
      partnerUnlockCode: yup.string().notRequired(),
      accessLevel: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .notRequired(),
      article: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .notRequired(),
      metaTitle: yup.string(),
      metaDescription: yup.string(),
      metaImageUrl: yup
        .mixed()
        .notRequired()
        .when('metaImageUrl', {
          is: (metaImageUrl) => metaImageUrl,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),
      jsonLdData: yup
        .object()
        .notRequired()
        .shape({
          name: yup.string().nullable(),
          alternateName: yup
            .array()
            .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
          keywords: yup
            .array()
            .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
          description: yup.string().nullable(),
          email: yup.string().nullable(),
          spatialCoverage: yup.string().nullable(),
          temporalCoverage: yup.string().nullable(),
        }),
    },
    [
      ['mobileImageUrl', 'mobileImageUrl'],
      ['metaImageUrl', 'metaImageUrl'],
    ]
  );

export default projectSchema;
