import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap';
import { useQueryClient } from 'react-query';

import { useFilterContext } from '@contexts/FilterContext';
import { useNewsletters, useSubscribedAdministratorLists } from '@hooks';
import { ADMINISTRATOR_LISTS_KEY } from '@hooks/useAdministratorLists';
import { CRM_IDENTITIES_COUNT_KEY, CRM_IDENTITIES_KEY } from '@hooks/useIdentities';
import {
  bulkDestroyIndentityAdministratorLists,
  createIdentityAdministratorList,
} from '@utils/backend-api/identity-administrator-lists';
import { updateStarredIdentitySources } from '@utils/backend-api/identity-sources';
import { createInformationComps } from '@utils/backend-api/information-comps';
import {
  cancelNewsletterSubscriptionAction,
  createNewsletterSubscriptionAction,
} from '@utils/backend-api/newsletter-subscription-actions';

import EmailForm from './IdentityModal/EmailForm';

const TI_COMP_OPTIONS = [
  { label: '1 day', value: 1 },
  { label: '1 week', value: 7 },
  { label: '1 month', value: 30 },
  { label: '1 year', value: 365 },
];

const BulkActionModal = ({
  bulkAction,
  setBulkAction,
  title,
  identities,
  selectAll,
  filters,
  query,
  administratorListId,
}) => {
  const handleClose = () => setBulkAction(null);
  const { administratorLists, isLoadingAdministratorLists } =
    useSubscribedAdministratorLists('subscribed');
  const { newsletters, isLoadingNewsletters } = useNewsletters();
  const [itemIds, setItemIds] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const { crmScope } = useFilterContext();

  const toggleItem = (currentCheckbox) => {
    if (currentCheckbox.checked) {
      setItemIds([...itemIds, currentCheckbox.value]);
    } else {
      setItemIds(itemIds.filter((val) => val !== currentCheckbox.value));
    }
  };

  const identityIds = () => {
    return identities.map((identity) => identity.id);
  };

  const buttonText = () => {
    switch (bulkAction) {
      case 'add-to-list':
        return 'Add To Lists';
      case 'remove-from-list':
        return 'Remove From Lists';
      case 'comp':
        return 'Comp Newsletters';
      case 'comp-information':
        return 'Give Comps';
      case 'cancel-subscriptions':
        return 'Cancel Newsletters';
      case 'trial':
        return 'Give Trial';
      case 'star':
        return 'Star Identities';
      case 'unstar':
        return 'Unstar Identities';
      case 'send-email':
        return `Send ${identityIds().length} Emails`;
    }
  };

  const handleAddToLists = async () => {
    setIsSubmitting(true);
    await makeBulkCall();
    setIsSubmitting(false);
  };

  const refectchIdentitites = () => {
    queryClient.invalidateQueries(CRM_IDENTITIES_KEY);
    queryClient.invalidateQueries(CRM_IDENTITIES_COUNT_KEY);
  };

  const makeBulkCall = async () => {
    try {
      let response = null;
      switch (bulkAction) {
        case 'add-to-list':
          response = await createIdentityAdministratorList({
            administratorListIds: itemIds,
            identityIds: identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            scope: crmScope,
          });
          queryClient.invalidateQueries([ADMINISTRATOR_LISTS_KEY, 'subscribed']);
          refectchIdentitites();
          break;
        case 'remove-from-list':
          response = await bulkDestroyIndentityAdministratorLists(
            itemIds,
            identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            crmScope
          );
          queryClient.invalidateQueries([ADMINISTRATOR_LISTS_KEY, 'subscribed']);
          refectchIdentitites();
          break;
        case 'comp':
          response = await createNewsletterSubscriptionAction({
            newsletterIds: itemIds,
            identityIds: identityIds(),
            type: 'comp',
            selectAll,
            filters,
            query,
            administratorListId,
            scope: crmScope,
          });
          break;
        case 'cancel-subscriptions':
          response = await cancelNewsletterSubscriptionAction({
            newsletterIds: itemIds,
            identityIds: identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            scope: crmScope,
          });
          break;
        case 'trial':
          response = await createNewsletterSubscriptionAction({
            newsletterIds: itemIds,
            identityIds: identityIds(),
            type: 'trial',
            selectAll,
            filters,
            query,
            administratorListId,
            scope: crmScope,
          });
          break;
        case 'comp-information':
          response = await createInformationComps(
            itemIds[0],
            identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            crmScope
          );
          break;
        case 'star':
          response = await updateStarredIdentitySources(
            true,
            identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            crmScope
          );
          refectchIdentitites();
          break;
        case 'unstar':
          response = await updateStarredIdentitySources(
            false,
            identityIds(),
            selectAll,
            filters,
            query,
            administratorListId,
            crmScope
          );
          refectchIdentitites();
          break;
      }
      if (response.error) setError(response.error);
      if (response.message) setMessage(response.message);
    } catch (e) {
      console.error('makeBulkCall: ', e);
    }
  };

  useEffect(() => {
    if (['star', 'unstar'].includes(bulkAction)) {
      setIsDisabled(false);
    } else {
      if (isSubmitting || itemIds.length == 0) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [isSubmitting, itemIds.length, bulkAction]);

  return (
    <Modal show={!!bulkAction}>
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
      </Modal.Header>
      <ModalBody>
        {error ? (
          <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: error }}></div>
        ) : message ? (
          <div className="alert alert-success" dangerouslySetInnerHTML={{ __html: message }}></div>
        ) : null}
        {['add-to-list', 'remove-from-list'].includes(bulkAction) ? (
          <>
            {isLoadingAdministratorLists ? (
              'Loading'
            ) : (
              <ul className="list-unstyled">
                {administratorLists
                  .filter((al) => al.listType === 'manual')
                  .map((administratorList) => (
                    <li key={administratorList.id} className="administrator-list-item">
                      <input
                        type="checkbox"
                        onChange={(e) => toggleItem(e.currentTarget)}
                        value={administratorList.id}
                        className="align-text-bottom me-1"
                      />
                      <span className="name">{administratorList.listName}</span>
                      <span className="list-count ps-1">({administratorList.identityCount})</span>
                    </li>
                  ))}
              </ul>
            )}
          </>
        ) : ['comp', 'cancel-subscriptions', 'trial'].includes(bulkAction) ? (
          <>
            {isLoadingNewsletters ? (
              'Loading'
            ) : (
              <ul className="list-unstyled">
                {newsletters.map((newsletter) => (
                  <li key={newsletter.id} className="newsletter-item">
                    <input
                      type="checkbox"
                      onChange={(e) => toggleItem(e.currentTarget)}
                      value={newsletter.id}
                      className="align-text-bottom me-1 newsletter-checkbox"
                    />
                    <span className="name">{newsletter.name}</span>
                  </li>
                ))}
              </ul>
            )}
          </>
        ) : bulkAction === 'comp-information' ? (
          <ul className="list-unstyled">
            {TI_COMP_OPTIONS.map((option) => (
              <li key={option.value}>
                <input
                  type="radio"
                  name="duration"
                  onChange={(e) => setItemIds([e.currentTarget.value])}
                  value={option.value}
                  className="align-text-bottom me-1"
                />
                <span>{option.label}</span>
              </li>
            ))}
          </ul>
        ) : ['star', 'unstar'].includes(bulkAction) ? (
          <div>
            {bulkAction === 'star' ? 'Star' : 'Unstar'} {selectAll ? 'all' : identityIds().length}{' '}
            identities
          </div>
        ) : bulkAction === 'send-email' ? (
          <div>
            <EmailForm identities={identities} />
          </div>
        ) : null}
      </ModalBody>
      {bulkAction !== 'send-email' && (
        <ModalFooter className="justify-content-between">
          <button className="btn btn-primary" onClick={handleAddToLists} disabled={isDisabled}>
            {isSubmitting ? 'processing...' : buttonText()}
          </button>
          <button type="button" className="btn btn-secondary" onClick={handleClose}>
            Cancel
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default BulkActionModal;
