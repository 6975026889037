import debounce from 'debounce-promise';
import Tribute from 'tributejs';

import Autosave from '../src/autosave';

const DEBOUNCE_MS = 500;
const DEFAULT_ARTICLE_IMAGE =
  'https://tii.imgix.net/production/articles/article_default_rebrand.jpg';
const WEEKEND_THEME = 'weekend';

window.addEventListener('load', async () => {
  const isBundledNewsletterContainer = document.querySelector('[data-ti-bundled]');
  if (isBundledNewsletterContainer.dataset.tiBundled == 'false') {
    const publish_on_web_field = document.querySelector('#article_publish_on_web');
    publish_on_web_field.checked = false;
    publish_on_web_field.value = '0';
    await Autosave.autosaveSettings(); // because we changed the value to published_on_web
  }
});

const readURL = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imgPreview = document.querySelector('#article-picture-uploader');
      imgPreview.style.backgroundImage = `url(${e.target.result})`;
    });
    reader.readAsDataURL(input.files[0]);
  }
};

const imgUploadField = document.querySelector('#article-picture-upload-field');
imgUploadField.addEventListener('change', (e) => readURL(e.target));

const tags = document.querySelectorAll('.tag');
tags.forEach((t) => t.addEventListener('click', (e) => e.currentTarget.remove()));

const tagInput = document.querySelector('#tag-input');
const tribute = new Tribute({
  selectClass: 'highlighted',
  autocompleteMode: true,
  values: debounce(async (_, cb) => cb(await remoteSearch(tagInput.innerText)), DEBOUNCE_MS),
  menuShowMinLength: 2,
  containerClass: 'tribute-container tags-tribute-container',
  // If no match, create a similar-looking element for user to "create their own"
  noMatchTemplate: () =>
    `<li class="highlighted" data-index="0"><div class="no-match">${tagInput.innerText.toLowerCase()}</div></li>`,
  allowSpaces: true,
  fillAttr: 'tag',
  replaceTextSuffix: '',
  menuContainer: tagInput.parentElement,
  positionMenu: false,
  menuItemTemplate: (entry) => {
    // Wrap <span> around the matching part of the tag to highlight it
    const regex = new RegExp(tagInput.innerText, 'i');
    const tagWithHighlight = entry.original.label.replace(
      regex,
      '<span class="autocomplete-highlighted">$&</span>'
    );
    return `<div>${tagWithHighlight}</div>`;
  },
  selectTemplate: (item) => {
    const dropdown = document.querySelector('.tags-tribute-container');
    dropdown.style.display = 'none';
    // Tag is the result, or the nomatch content
    const tag = item ? item.original.label : document.querySelector('.no-match').innerHTML;
    saveResult(tag);
    return '';
  },
});
tribute.attach(tagInput);

const remoteSearch = async (text) => {
  const url = `${tagInput.dataset.autocomplete}?fragment=${encodeURIComponent(
    text.replaceAll('&nbsp;', ' ')
  )}`;
  const response = await fetch(url);
  if (response.ok) {
    const responseBody = await response.json();
    return responseBody.entities;
  } else {
    return [];
  }
};

const saveResult = (result) => {
  const newTagElt = document.createElement('span');
  newTagElt.classList.add('tag');
  newTagElt.innerHTML = `<span class="tag-value">${result}</span><i class="fa fa-times" aria-hidden="true"></i>`;
  newTagElt.addEventListener('click', (e) => e.currentTarget.remove());
  tagInput.insertAdjacentElement('beforebegin', newTagElt);
  tagInput.innerHTML = '';
};

// Contenteditable is a bit hacky, so if the user deletes the first character of the text box
// Instead change the div's innerHTML and prevent the backspace action
tagInput.addEventListener('keydown', (e) => {
  if (e.keyCode === 8 && tagInput.innerHTML.length === 1) {
    tagInput.innerHTML = '';
    e.preventDefault();
  }
});

tagInput.addEventListener('keyup', () => {
  if (tagInput.innerHTML === '') {
    tribute.hideMenu();
    return;
  }
  tribute.showMenuForCollection(tagInput);
});

// If a user attempts to paste into the tag contenteditable from google docs, strip HTML in the pasted content
tagInput.addEventListener('paste', (e) => {
  // Use the browser's ability to sanitize HTML to remove tags
  let paste = (e.clipboardData || window.clipboardData).getData('text');
  const doc = new DOMParser().parseFromString(paste, 'text/html');
  paste = doc.body.textContent || '';

  // Insert the sanitized HTML as a new node (to get around self-XSS attacks)
  const selection = window.getSelection();
  selection?.deleteFromDocument();
  selection?.getRangeAt(0).insertNode(document.createTextNode(paste));
  e.preventDefault();

  // Move the caret to the end of the contenteditable, so that Tribute sees the pasted content
  tribute.placeCaretAtEnd(tagInput);
});

tagInput.addEventListener('tribute-active-true', () =>
  tagInput.closest('#select-tags').classList.add('autocomplete-menu-open')
);
tagInput.addEventListener('tribute-active-false', () =>
  tagInput.closest('#select-tags').classList.remove('autocomplete-menu-open')
);

const form = document.querySelector('#edit-newsletter-settings-form');
const saveWarning = document.querySelector('#failed-save');
const saveWarningText = saveWarning.querySelector('span');
const saveSuccess = document.querySelector('#last-saved-at');

let saveImage = false;
const autosaveOrWarn = async (e) => {
  if (e?.target && e.target.name === 'article[picture]') {
    saveImage = true;
  }
  if (form.dataset.autosave === 'true') {
    await Autosave.autosaveSettings(form, saveImage);
  } else {
    saveSuccess.classList.remove('--green');
    saveSuccess.classList.add('--grey');
    saveWarningText.innerHTML =
      'This article has already been embargoed or published.<br>Click the "Update Embargoed/Published Article" button to save changes.';
    saveWarning.style.display = 'block';
  }
};

let timeout;
['keyup', 'change'].forEach((action) => {
  form.addEventListener(action, (e) => {
    e.stopImmediatePropagation();
    clearTimeout(timeout);
    timeout = setTimeout(() => autosaveOrWarn(e), 500);
  });
});

const observer = new MutationObserver(autosaveOrWarn);
observer.observe(document.querySelector('#select-tags'), { childList: true });

Autosave.initializeLastSavedNotification();

// Updating an already published article
const updateBtn = document.querySelector('#update-button');
const resendBtn = document.querySelector('.resend-btn');
const resendEmailCheckBox = document.querySelector('#resend_email_delivery');
if (updateBtn) {
  updateBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    if (e?.target.name === 'article[picture]') {
      saveImage = true;
    }
    await Autosave.autosaveSettings(form, saveImage);
    if (resendBtn && resendEmailCheckBox) {
      if (resendEmailCheckBox.checked) {
        resendBtn.classList.remove('hidden');
        resendBtn.classList.remove('disabled');
      } else {
        resendBtn.classList.add('hidden');
        resendBtn.classList.add('disabled');
      }
    }
  });
}

const continueBtn = document.getElementById('continue-button');
if (continueBtn) {
  // Disable the continue button if the user hasn't selected any recipients
  const recipientsCheckboxes = document.querySelectorAll(
    '.recipient-option-checkbox input[type="checkbox"]'
  );
  const articleImage = document.querySelector('#article-picture-uploader');

  const checkIfUserCanContinue = () => {
    const articleImageUploaded = isImageUploadedForWeekendNewsletter();
    const recipientsSelected = [...recipientsCheckboxes].reduce(
      (hasRecipients, checkbox) => hasRecipients || checkbox.checked,
      false
    );
    if (recipientsSelected && articleImageUploaded) {
      continueBtn.classList.remove('disabled');
    } else {
      continueBtn.classList.add('disabled');
    }
  };

  const isImageUploadedForWeekendNewsletter = () => {
    const newsletterTheme = document.querySelector('#newsletter-theme').innerHTML;
    if (newsletterTheme !== WEEKEND_THEME) return true;

    const articleImageStyles =
      articleImage.currentStyle || window.getComputedStyle(articleImage, false);
    const backgroundImage = articleImageStyles.backgroundImage.slice(4, -1).replace(/"/g, '');
    return backgroundImage !== DEFAULT_ARTICLE_IMAGE;
  };

  document.addEventListener('DOMContentLoaded', checkIfUserCanContinue);
  recipientsCheckboxes.forEach((checkbox) =>
    checkbox.addEventListener('change', checkIfUserCanContinue)
  );

  // Observe changes in the article image uploader style element
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function () {
      checkIfUserCanContinue();
    });
  });
  observer.observe(articleImage, { attributes: true, attributeFilter: ['style'] });
}

const emailFreeBlurbTrue = document.querySelector('#article_email_free_blurb_true');
const resendEmailContainer = document.querySelector('#resend-email-option');
const emailFreeBlurbFalse = document.querySelector('#article_email_free_blurb_false');
const sendRtsuSection = document.querySelector('#send-rtsu-section');
const deliveryOptions = document.querySelector('.delivery-options');
const archivePublishedAt = document.querySelector('.archive-published-at-section');
const setDeliveryOptions = () => {
  if (emailFreeBlurbTrue.checked) {
    if (resendEmailContainer) {
      resendEmailContainer.classList.remove('hidden');
    }
    deliveryOptions.classList.remove('hidden');
    archivePublishedAt.classList.add('hidden');
    sendRtsuSection?.classList.remove('hidden');
  } else if (emailFreeBlurbFalse.checked) {
    archivePublishedAt.classList.remove('hidden');
    deliveryOptions.classList.add('hidden');
    sendRtsuSection.classList.add('hidden');
    if (resendEmailContainer) {
      resendEmailContainer.classList.add('hidden');
    }
  }
};
document.addEventListener('DOMContentLoaded', setDeliveryOptions);
emailFreeBlurbTrue.addEventListener('change', (e) => {
  e.preventDefault();
  setDeliveryOptions();
});
emailFreeBlurbFalse.addEventListener('change', (e) => {
  e.preventDefault();
  setDeliveryOptions();
});

document.querySelectorAll('.recipient-option-group-button').forEach((recipientOptionButton) => {
  recipientOptionButton.addEventListener('click', (e) => {
    e.stopImmediatePropagation();
    // Opens and closes the administrator's lists.
    e.target.classList.toggle('recipient-option-group-button-open');
  });
});

const pushFields = document.querySelectorAll('#article_push_title, #article_push_message');
pushFields.forEach((field) => {
  const counter = field.parentElement.querySelector('.newsletter-settings-push-field-counter');
  field.addEventListener('keyup', () => {
    const length = field.value.length;
    counter.querySelector('span').innerHTML = length;
    if (length > field.dataset.maxLength) {
      counter.classList.add('--red');
    } else {
      counter.classList.remove('--red');
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const articleTitle = document.querySelector('#article_title');
  const articleSlugWarning = document.querySelector('#article-slug-warning');
  const resendEmailCheckBox = document.querySelector('#resend_email_delivery');
  const recipientContainer = document.querySelector('#recipient-options-section');
  const resendBtn = document.querySelector('.resend-btn');
  if (resendEmailCheckBox) {
    resendEmailCheckBox.addEventListener('change', (e) => {
      if (e.target.checked) {
        recipientContainer.classList.remove('disabled');
      } else {
        recipientContainer.classList.add('disabled');
        resendBtn.classList.add('hidden');
        resendBtn.classList.add('disabled');
      }
    });
  }
  articleTitle.addEventListener('input', () => {
    if (articleSlugWarning) {
      articleSlugWarning.classList.remove('hidden');
    }
  });
});
