import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useAdScheduleCreate, useAdvertisement } from '../../hooks';
import { AdScheduleForm } from '../../utils/backend-api/ad-schedules';
import { AdvertisementFormValues } from '../../utils/backend-api/advertisements';
import AdvertisementForm, { INITIAL_STATE_AD } from './AdvertisementForm';
import ScheduleForm from './ScheduleForm';

const INITIAL_STATE_SCHEDULE = {
  newSchedule: null,
};

/**
 * EditAdvertisement: Edit page for existing advertisements.
 * Uses shared AdvertisementForm component.
 */
const EditAdvertisement = () => {
  const { id: advertisementId } = useParams();
  const { advertisement, isLoadingAdvertisement, isFetching } = useAdvertisement(advertisementId);
  const { mutateAsync: createAdSchedule } = useAdScheduleCreate();
  const [scheduleGroups, setScheduleGroups] = useState([]);
  const [formattedSectionOptions, setFormattedSectionOptions] = useState(null);
  const [scheduling, setScheduling] = useState(false);
  const [adFormValues, setAdFormValues] = useState<AdvertisementFormValues>(INITIAL_STATE_AD);
  const [scheduleFormValues, setScheduleFormValues] =
    useState<AdScheduleForm>(INITIAL_STATE_SCHEDULE);

  useEffect(() => {
    if (!isLoadingAdvertisement && !isFetching) {
      for (const key in advertisement) {
        // replace null values with empty string
        if (advertisement[key] === null) {
          advertisement[key] = '';
        }
      }
      const {
        adImageImgixUrl: image,
        administratorList,
        clickthroughUrl,
        entities,
        externalImageUrl,
        formattedSectionOptions,
        header,
        scheduleGroups,
        sponsorLogoImgixUrl: sponsorLogo,
        sponsorName,
        text,
        trackingPixelUrl,
      } = advertisement;

      const adFormValues = {
        clickthroughUrl,
        entities: entities.map(({ id, formattedName }) => ({
          label: formattedName,
          value: id,
        })),
        excludeList: {
          label: administratorList?.listName || '',
          value: administratorList?.id || 0,
        },
        externalImageUrl,
        header,
        image,
        sponsorLogo,
        sponsorName: { label: sponsorName, value: sponsorName },
        text,
        trackingPixelUrl,
      };

      setFormattedSectionOptions(formattedSectionOptions);
      setAdFormValues(adFormValues);
      setScheduleGroups(scheduleGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAdvertisement, isFetching]);

  const handleToggleEditing = () => {
    setScheduling((prev) => !prev);
  };

  const handleSubmitSchedule = async (values: AdScheduleForm) => {
    const {
      newSchedule: { startDate, endDate, sections },
    } = values;
    const sanitizedParams = {
      advertisementId,
      startDate,
      endDate,
      sections,
    };
    await createAdSchedule(sanitizedParams, {
      onSuccess: (data) => {
        const addedSchedule = {
          startDate: data.startDate,
          endDate: data.endDate,
          sections: data.createdAdSchedules,
        };
        setScheduleGroups((prev) =>
          [...prev, addedSchedule].sort((a, b) =>
            moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1
          )
        );
        setScheduleFormValues({ newSchedule: null });
        setScheduling(false);
      },
    });
  };

  if (isLoadingAdvertisement) return null;
  return (
    <Container className="NewAdvertisement">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-1 text-center">
                {advertisement.sponsorName} - {advertisement.header}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <hr className="divider" />
            </Col>
          </Row>
          <AdvertisementForm
            articlePreviewUrl={advertisement.articlePreviewUrl}
            formValues={adFormValues}
            scheduling={scheduling}
          />
          <hr className="my-4" />
          <ScheduleForm
            formValues={scheduleFormValues}
            handleToggleEditing={handleToggleEditing}
            onSubmit={handleSubmitSchedule}
            scheduling={scheduling}
            sectionOptions={formattedSectionOptions}
            updateFormValues={setScheduleFormValues}
          />
          <Table striped bordered hover id="AdvertisementAdSchedules">
            <thead>
              <tr className="text-center">
                <th>Start Date</th>
                <th>End Date</th>
                <th>Sections</th>
              </tr>
            </thead>
            <tbody>
              {scheduleGroups.map((group) => (
                <tr key={`${group.startDate}-${group.endDate}`} className="text-center">
                  <td>{moment(group.startDate).format('ll')}</td>
                  <td>{moment(group.endDate).format('ll')}</td>
                  <td>
                    {group.sections.map(({ id, section }) => (
                      <div key={id}>{section}</div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAdvertisement;
