import * as yup from 'yup';

const RECIPIENT_VALIDATION_TEXT =
  'Either a recipient group or an administrator list is required, but you can not use both';

const newPushNotificationSchema = () =>
  yup.object().shape({
    pushTitle: yup.string().required('Title is required'),
    pushMessage: yup
      .string()
      .max(120, 'Must be 120 characters or less')
      .required('Message is required'),
    link: yup.string().url().required('URL is Required'),
    includedRecipients: yup.array().test('xor', RECIPIENT_VALIDATION_TEXT, (_, context) => {
      return (
        !!context.parent.includedRecipients.length !== !!context.parent.administratorList.value
      );
    }),
    excludedRecipients: yup.array(),
    administratorList: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.number(),
      })
      .test('xor', RECIPIENT_VALIDATION_TEXT, (_, context) => {
        return (
          !!context.parent.includedRecipients.length !== !!context.parent.administratorList.value
        );
      }),
    xor: yup.bool(),
  });

export default newPushNotificationSchema;
