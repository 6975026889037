import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import ProjectForm from './ProjectForm';
import './Projects.scss';

const NewProject = () => {
  return (
    <Container className="NewProject">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-1">Setup new project</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <hr className="divider" />
            </Col>
          </Row>
          <ProjectForm />
        </Col>
      </Row>
    </Container>
  );
};

export default NewProject;
