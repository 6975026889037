import * as yup from 'yup';

import { imageTest } from '../../../utils/helpers';

const VALID_URL_MESSAGE = 'Must be a valid URL (usually starting "http://")';
// YUP's url regex validation doesn't accept brackets. https://github.com/jquense/yup/issues/1492
const PIXEL_URL_REGEX =
  // eslint-disable-next-line
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

const advertisementSchema = () =>
  yup.object().shape(
    {
      sponsorName: yup.object().shape({
        label: yup.string().required('Sponsor name is required'),
        value: yup.string().required('Sponsor name is required'),
      }),
      sponsorNameMessageHead: yup.string().nullable(),
      sponsorNameMessageBody: yup.string().nullable(),
      header: yup.string().required('Header is required'),
      text: yup.string().required('Text is required'),
      clickthroughUrl: yup.string().url(VALID_URL_MESSAGE).required('URL is Required'),
      image: yup.mixed().when('externalImageUrl', {
        is: (externalImageUrl) => !externalImageUrl,
        then: yup
          .mixed()
          .required('Image or External image URL is required')
          // TODO: determine appropriate file size
          // .test('fileSize', 'File Size is too large', (value) => value.size <= 500)
          .test('fileType', 'Unsupported File Format', imageTest),
      }),
      sponsorLogo: yup
        .mixed()
        .notRequired()
        .when('sponsorLogo', {
          is: (sponsorLogo) => sponsorLogo,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),

      externalImageUrl: yup.string().nullable().url(VALID_URL_MESSAGE),
      trackingPixelUrl: yup.string().nullable().matches(PIXEL_URL_REGEX, VALID_URL_MESSAGE),
      excludeList: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .nullable()
        .notRequired(),
      entities: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          value: yup.number(),
        })
      ),
    },
    [['sponsorLogo', 'sponsorLogo']]
  );

export default advertisementSchema;
