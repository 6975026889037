import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { useProject } from '@hooks';
import { autocompleteArticles } from '@utils/backend-api/articles';
import { AutocompleteOption } from '@utils/interfaces';

import { ProjectFormValues, updateProject } from '../../../utils/backend-api/projects';
import {
  AutocompleteField,
  DropdownField,
  ImageField,
  MultiValueAutocompleteField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import schema from './schema';

export const INITIAL_STATE = {
  accessLevel: {
    label: 'All',
    value: 0,
  },
  article: {
    label: '',
    value: '',
  },
  byline: '',
  csvColumns: '',
  description: '',
  desktopImageUrl: '',
  googleSheetId: '',
  jsonLdData: {
    alternateName: [],
    description: '',
    email: '',
    keywords: [],
    name: '',
    spatialCoverage: '',
    temporalCoverage: '',
  },
  metaDescription: '',
  metaImageUrl: '',
  metaTitle: '',
  mobileImageUrl: '',
  partnerUnlockCode: '',
  slug: '',
  title: '',
};

interface ProjectFormProps {
  formValues?: ProjectFormValues;
}

const ProjectForm = ({ formValues = INITIAL_STATE }: ProjectFormProps) => {
  const { id: projectId } = useParams();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { refetch: refetchProject } = useProject(projectId);

  const expandedFormRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const accessLevelOptions = [
    { label: 'All', value: 0 },
    { label: 'Leads + Subscribers', value: 1 },
    { label: 'All Subscribers', value: 2 },
    { label: 'Annual + Pro Subscribers', value: 3 },
    { label: 'Pro Subscribers', value: 4 },
  ];

  const handleSubmit = async (values: ProjectFormValues) => {
    const { accessLevel, article, jsonLdData, ...restVals } = values;
    const { alternateName, keywords, ...restJsonLdData } = jsonLdData;
    const sanitizedParams = {
      ...restVals,
      accessLevel: accessLevel.value || 0,
      articleId: article.value || '',
      jsonLdData: JSON.stringify({
        ...restJsonLdData,
        keywords: keywords.map((option: AutocompleteOption) => option.value),
        alternateName: alternateName.map((option: AutocompleteOption) => option.value),
      }),
    };
    // create FormData object so we can send request with content-type form-data for image uploading
    const formData = new FormData();
    for (const key in sanitizedParams) {
      formData.append(key, sanitizedParams[key]);
    }
    try {
      const resp = await updateProject(formData);
      if (resp.message === 'ok') {
        if (resp.redirect_url) {
          toast.success('Project created!');
          navigate(resp.redirect_url);
        } else {
          toast.success('Project updated!');
          refetchProject();
        }
      }
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
    }
  };

  useEffect(() => {
    const { current } = expandedFormRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {(formik) => (
        <Form className="ProjectForm" noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <TextField label="Title*" name="title" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField hint="Accepts HTML" label="Description*" rows={4} name="description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Slug*" name="slug" />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField label="Desktop image*" name="desktopImageUrl" height="auto" width={320} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField
                height="auto"
                hint="Defaults to desktop image if not provided"
                label="Mobile image"
                name="mobileImageUrl"
                width={320}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Byline" name="byline" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Google Sheet ID" name="googleSheetId" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField name="partnerUnlockCode" label="Partner unlock code" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownField name="accessLevel" label="Access level" options={accessLevelOptions} />
            </Col>
          </Row>
          <Row>
            <Col>
              <AutocompleteField
                fetchMethod={(fragment: string) => autocompleteArticles(fragment)}
                hint="Search for articles by title"
                isCreatable={false}
                label="Associated article"
                name="article"
                valueIsInteger
              />
            </Col>
          </Row>
          <hr className="divider" />
          <Row className="mb-3 text-center">
            <h5 className="expand-btn" role="button" onClick={() => setIsExpanded(!isExpanded)}>
              Associated Data {!isExpanded ? '\u25B2' : '\u25BC'}
            </h5>
          </Row>
          {isExpanded && (
            <>
              <Row ref={expandedFormRef}>
                <Col>
                  <TextField
                    hint="Defaults to main title if not provided"
                    label="Meta title"
                    name="metaTitle"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaField
                    hint="Defaults to main description if not provided"
                    label="Meta description"
                    rows={4}
                    name="metaDescription"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ImageField
                    height="auto"
                    hint="Image should be 1.91:1 aspect ratio. Defaults to desktop image if not provided"
                    label="Meta Image"
                    name="metaImageUrl"
                    width={320}
                  />
                </Col>
              </Row>
              <hr className="divider mb-3" />
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to project title"
                    label="Dataset name"
                    name="jsonLdData.name"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MultiValueAutocompleteField
                    label="Dataset alternate names"
                    name="jsonLdData.alternateName"
                    placeholder="Add an alternate name..."
                    isCreatable
                    createMethod={() => undefined}
                    formatCreateLabel={(value: string) => `Add "${value}"`}
                    showPills
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MultiValueAutocompleteField
                    label="Dataset keywords"
                    name="jsonLdData.keywords"
                    placeholder="Add a keyword..."
                    isCreatable
                    createMethod={() => undefined}
                    formatCreateLabel={(value: string) => `Add "${value}"`}
                    showPills
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaField
                    hint="Defaults to project description"
                    label="Dataset Description"
                    rows={4}
                    name="jsonLdData.description"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to The Information support email"
                    label="Dataset email"
                    name="jsonLdData.email"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to 'Worldwide'"
                    label="Dataset spatial coverage"
                    name="jsonLdData.spatialCoverage"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="For example: 2023/.."
                    label="Dataset temporal coverage"
                    name="jsonLdData.temporalCoverage"
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                className="continue-button button --black"
                id="send"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectForm;
